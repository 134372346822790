import React, { useState, useEffect, useRef } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import PasswordInput from "../password/PasswordInput";
import PasswordRequirements from "../password/PasswordRequirements";

const ChangePasswordModal = ({
  showChangePassword,
  setShowChangePassword,
  passwords,
  handleInputChange,
  handlePasswordChange,
  loadingChangePassword,
}) => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [initialView, setInitialView] = useState(true);
  const [passwordStrength, setPasswordStrength] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
  });
  const modalRef = useRef(null);
  const handleClickOutside = (event) => {
    console.log(modalRef.current, event.target, 'sdcfsdcds');
    if (modalRef.current && !modalRef.current.contains(event.target)) {

      setShowChangePassword(false);
    }
  };
  const resetForm = () => {
    // Reset all password fields
    handleInputChange({ target: { name: "old_password", value: "" } });
    handleInputChange({ target: { name: "new_password", value: "" } });
    handleInputChange({ target: { name: "confirm_new_password", value: "" } });

    // Reset other states
    setShowOldPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
    setErrorMessage("");
    setInitialView(true);
    setPasswordStrength({
      length: false,
      uppercase: false,
      lowercase: false,
      number: false,
      special: false,
    });
  };

  const togglePasswordVisibility = (field) => {
    switch (field) {
      case "old":
        setShowOldPassword(!showOldPassword);
        break;
      case "new":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirm":
        setShowConfirmPassword(!showConfirmPassword);
        break;
    }
  };

  useEffect(() => {
    checkPasswordStrength(passwords.new_password);
  }, [passwords.new_password]);

  const checkPasswordStrength = (password) => {
    setPasswordStrength({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    });
  };

  const validatePasswords = () => {
    if (passwords.new_password !== passwords.confirm_new_password) {
      setErrorMessage("New passwords do not match.");
      return false;
    }
    if (passwords.old_password === passwords.new_password) {
      setErrorMessage("New password must be different from the old password.");
      return false;
    }
    if (!Object.values(passwordStrength).every(Boolean)) {
      setErrorMessage("Password does not meet all requirements.");
      return false;
    }
    setErrorMessage("");
    return true;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validatePasswords()) {
      handlePasswordChange();
    }
  };
  useEffect(() => {
    checkPasswordStrength(passwords.new_password);
    setInitialView(passwords.new_password.length === 0);
  }, [passwords.new_password]);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return showChangePassword ? (
    <div
      className="pointer-events-auto fixed inset-0 z-[1200] grid h-screen w-screen place-items-center
 bg-greeng 
 bg-opacity-60 opacity-100 backdrop-blur-sm transition-opacity duration-300"

    >
      <div ref={modalRef}
        className="relative z-60 bg-white p-4 md:p-6 lg:p-8 rounded-lg w-[90%] md:w-[50%] lg:w-[30%]">
        <h2 className="mb-4 text-lg font-bold text-center">Change Password</h2>
        <form onSubmit={onSubmit}>
          {errorMessage && (
            <div className="mb-4 text-red-500">{errorMessage}</div>
          )}
          <PasswordInput
            showPassword={showOldPassword}
            togglePasswordVisibility={() => togglePasswordVisibility("old")}
            name="old_password"
            placeholder="Current Password"
            value={passwords.old_password}
            onChange={handleInputChange}
          />

          <PasswordInput
            showPassword={showNewPassword}
            togglePasswordVisibility={() => togglePasswordVisibility("new")}
            name="new_password"
            placeholder="New Password"
            value={passwords.new_password}
            onChange={handleInputChange}
          />
          <div className="relative flex items-center w-full mb-3">
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="confirm_new_password"
              placeholder="Confirm New Password"
              value={passwords.confirm_new_password}
              onChange={handleInputChange}
              className="w-full p-3 pr-10 border rounded focus:outline-none focus:border-basegray  focus:border-2"
              required
            />
            <button
              type="button"
              onClick={() => togglePasswordVisibility("confirm")}
              className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-600"
            >
              {showConfirmPassword ? <FiEye /> : <FiEyeOff />}
            </button>
          </div>

          <PasswordRequirements
            passwordStrength={passwordStrength}
            initialView={initialView}
          />
          <div className="flex justify-between mt-3">
            <button
              type="submit"
              disabled={loadingChangePassword}
              className="px-4 py-2 text-white rounded-md bg-mainColor"
            >
              {loadingChangePassword ? "Changing..." : "Change Password"}
            </button>
            <button
              type="button"
              className="px-4 py-2 text-white bg-red-700 rounded-md"
              onClick={() => {
                resetForm();
                setShowChangePassword(false);
              }}
            >
              Cancel
            </button>
          </div>
        </form>
        <div className="mt-4 text-center">
          <a href="/forgot-password" className="text-red-500  underline">
            Forgot your password?
          </a>
        </div>
      </div>
    </div>
  ) : null;
};

export default ChangePasswordModal;
