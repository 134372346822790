import React, { memo, useEffect } from "react";
import Routes from "../pages/Routes/index";

const Content = ({
  show,
  processComplete,
  activeProject,
  setActiveProject,
  topThreeProjects,
  setTopThreeProjects,
  pin_project,
  setPin_project,
  isSidebarOpen, // Receive the prop
  getTopThreePinnedProjects


}) => {
  const marginLeftClass = show ? "ml-[15%] " : "px-[10px] ";
  return (
    <>
      <div
        className={`bg-baseColor dark:bg-darkbg
    absolute  ${marginLeftClass}
   transition-all fixed inset-0 
     duration-300 ease-in-out min-h-screen h-fit `}
      >
        <Routes
          processComplete={processComplete}
          activeProject={activeProject}
          setActiveProject={setActiveProject}
          setTopThreeProjects={setTopThreeProjects}
          topThreeProjects={topThreeProjects}
          pin_project={pin_project}
          setPin_project={setPin_project}
          show={show}
          getTopThreePinnedProjects={getTopThreePinnedProjects}
        />
      </div>
    </>
  );
};

export default memo(Content);
