

import { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createBookmark } from "../../redux/lib/createGroup";
import { fetchBookmarks } from "../../redux/lib/displayBookmarks";
import { fetchData } from "../../redux/lib/fetchData";

const useBookmarks = (projectId, userId, handleAddToBookmark, filteredData) => {
  const [newBookmarkName, setNewBookmarkName] = useState("");
  const [filteredBookmarks, setFilteredBookmarks] = useState([]);
  const [searchBookmark, setSearchBookmark] = useState("");
  const [editingBookmarkId, setEditingBookmarkId] = useState(null);
  const [selectedBookmark, setSelectedBookmark] = useState(null);
  const dispatch = useDispatch();
  const displayBookmarkSlice = useSelector((state) => state.displayBookmarkSlice.bookmarks);

  // Memoize displayBookmarkSlice to prevent unnecessary re-renders
  const memoizedBookmarks = useMemo(() => displayBookmarkSlice, [displayBookmarkSlice]);

  // Filter bookmarks based on searchBookmark
  useEffect(() => {
    setFilteredBookmarks(
      memoizedBookmarks.filter((bookmark) =>
        bookmark.name.toLowerCase().includes(searchBookmark.toLowerCase())
      )
    );
  }, [memoizedBookmarks, searchBookmark]);

  const fetchAllBookmarks = useCallback(async () => {
    if (projectId) {
      await dispatch(fetchBookmarks({ projectId }));
    }
  }, [dispatch, projectId]);

  // Fetch bookmarks when projectId changes
  useEffect(() => {
    fetchAllBookmarks();
  }, [fetchAllBookmarks]);

  const handleCreateBookmark = useCallback(async () => {
    if (newBookmarkName.trim() === "") {
      return;
    }

    // Check if the bookmark name already exists
    const nameExists = memoizedBookmarks.some(
      (bookmark) => bookmark.name.toLowerCase() === newBookmarkName.toLowerCase()
    );

    if (nameExists) {
      toast.error("A bookmark with this name already exists. Please choose a different name.");
      return;
    }

    const newBookmarkData = {
      name: newBookmarkName,
      projectId,
    };

    try {
      const createdBookmark = await dispatch(createBookmark(newBookmarkData)).unwrap();
      setFilteredBookmarks((prev) => [...prev, createdBookmark]);
      setNewBookmarkName("");
      await fetchAllBookmarks();
      handleAddToBookmark(createdBookmark.b_id);

      toast.info(`Bookmark "${newBookmarkName}" created successfully`, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("Failed to create bookmark:", error);
      toast.error(`Failed to create bookmark: ${error.message}`);
    }
  }, [dispatch, memoizedBookmarks, newBookmarkName, projectId, fetchAllBookmarks, handleAddToBookmark]);

  const handleDeleteBookmark = useCallback(async (userId, bookmarkId, projectId) => {
    const bookmarkToDelete = filteredBookmarks.find((b) => b.b_id === bookmarkId);
    if (!bookmarkToDelete) {
      console.error("Bookmark not found");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/bookmarks/delete/${userId}/${bookmarkId}/${projectId}/`,
        {
          method: "DELETE",
        }
      );
      if (!response.ok) {
        throw new Error("Error deleting bookmark");
      }

      setFilteredBookmarks((prev) => prev.filter((bookmark) => bookmark.b_id !== bookmarkId));
      setSelectedBookmark(null); // Clear the selected bookmark state

      await fetchAllBookmarks();

      toast.info(`Bookmark "${bookmarkToDelete.name}" deleted successfully`, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      await dispatch(fetchData({ userId, projectId }));
    } catch (error) {
      console.error("Failed to delete bookmark:", error);
      toast.error(`Failed to delete bookmark: ${error.message}`);
    }
  }, [filteredBookmarks, fetchAllBookmarks, dispatch]);

  const handleRenameBookmark = useCallback(async (bookmarkId, newName, projectId) => {
    const bookmarkToRename = filteredBookmarks.find((b) => b.b_id === bookmarkId);
    if (!bookmarkToRename) {
      console.error("Bookmark not found");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/bookmarks/update/${userId}/${bookmarkId}/${projectId}/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name: newName }),
        }
      );

      if (!response.ok) {
        throw new Error("Error updating bookmark");
      }

      setFilteredBookmarks((prev) =>
        prev.map((bookmark) => (bookmark.b_id === bookmarkId ? { ...bookmark, name: newName } : bookmark))
      );

      await fetchAllBookmarks();
      setEditingBookmarkId(null);
      toast.info(`Bookmark renamed from "${bookmarkToRename.name}" to "${newName}"`, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("Failed to update bookmark:", error);
      toast.error(`Failed to rename bookmark: ${error.message}`);
    }
  }, [filteredBookmarks, fetchAllBookmarks]);

  return {
    filteredBookmarks,
    newBookmarkName,
    setNewBookmarkName,
    handleCreateBookmark,
    handleDeleteBookmark,
    handleRenameBookmark,
    editingBookmarkId,
    setEditingBookmarkId,
    searchBookmark,
    setSearchBookmark,
    fetchAllBookmarks,
    setFilteredBookmarks,
    selectedBookmark, // Return selectedBookmark state
    setSelectedBookmark, // Return setSelectedBookmark function
  };
};

export default useBookmarks;
