import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { FiPlus } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import axios from "axios";

const InputForm = ({ onSubmit, isLoading }) => {
  const { register, handleSubmit, setValue } = useForm();
  const [quota, setQuota] = useState()

  const userId = useSelector((state) => state.authSlice.id);

  const verified = useSelector((state) => state.authSlice.verified);
  const EmailUser = useSelector((state) => state.authSlice.user.Email); // Assuming you have EmailUser in your state
  const sanitizeInput = (input) => {
    // return input.replace(/[^a-zA-Z0-9 @.\u0600-\u06FF]/g, ""); // Arabic characters range: \u0600-\u06FF
    // return input.replace(/[^a-zA-Z0-9 @.\-\/?:=&\u0600-\u06FF]/g, "");
    return input.replace(/[^a-zA-Z0-9 @.\-\/?:=&%\u0600-\u06FF]/g, "");


  };
  // const handleFormSubmit = async (data) => {
  //   await onSubmit(data.input);
  // };
  const handleInputChange = (event) => {
    const sanitizedValue = sanitizeInput(event.target.value); // Sanitize in real-time
    setValue("input", sanitizedValue); // Update form value with sanitized input
    console.log(sanitizedValue, event.target.value, 'event.target.value');

  };
  const fetchQouta = useCallback(async () => {

    await axios.get(`${process.env.REACT_APP_API_URL}/api/UserProfileQuote/${userId}/`).then((res) => {
      setQuota(res.data)

    })


  }, []);
  useEffect(() => {
    fetchQouta()
  }, [fetchQouta])
  const handleFormSubmit = async (data) => {
    // set_button_refresh(false)

    const sanitizedInput = sanitizeInput(data.input); // Sanitize the input
    await onSubmit(sanitizedInput); // Pass sanitized input to onSubmit

  };
  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className="relative md:flex items-center 
       tooltipcontainer    w-full
       dark:bg-darkbg rounded-lg"
    >
      <input
        required
        {...register("input", { required: "Input is required" })}
        onChange={handleInputChange}
        className={`p-2 outline-none focus:outline-none focus:ring-0 dark:bg-slate ${quota?.daily_quota_KR === 0 ? "w-full" : "w-4/5"
          } md:rounded-l-lg md:rounded-r-none rounded-lg border border-r-0`}
        placeholder="Enter your Keyword or URL..."
        disabled={isLoading || !verified}
      />
      <button
        type="submit"
        disabled={isLoading || !verified} // Disable if loading or not verified
        className={`md:w-1/6 w-full bg-mainColor text-center 
            flex items-center justify-center  
          md:rounded-r-lg  md:rounded-l-none rounded-lg md:my-0 my-2
           text-white p-2 ${isLoading || !verified ? "opacity-50 cursor-not-allowed" : ""
          }`}
      >
        {isLoading && (
          <svg
            className="w-5 h-5 mr-3 tex
            t-white animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
            ></path>
          </svg>
        )}
        {isLoading ? "Loading..." : "Submit"}
      </button>


      <p
  className={`text-sm ml-3 ${quota?.daily_quota_KR === 0 ? "text-red-800" : "text-mainColor"
    }`}
>
  <span>
    {quota?.daily_quota_KR}/
    {quota?.billing_type === 1
      ? "1000"
      : quota?.billing_type === 2
      ? "500"
      : quota?.billing_type === 3
      ? "250"
      : quota?.billing_type === 10
      ? "20000"
      : ""} {/* Handle unknown or custom billing types */}
  </span>
  <span className="block">Remaining Keyword</span>
</p>



      {!verified && (

        <div className="  absolute w-full bottom-full  transition-all duration-1000 ease-in-out flex items-end justify-end">
          <span
            className=" hidden md:block tooltiptext 
           mb-2 w-fit py-2 px-5 bg-gray-700 text-white text-center text-sm rounded-md "
          >
            Please verify your account through email
            <span className="underline"> {EmailUser}</span>
          </span>
        </div>
      )}
    </form>
  );
};

export default InputForm;
