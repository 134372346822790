import React from "react";
import Select from "react-select";
import customStyles from "../CustomStyling/customStyles";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const InviteUserModal = ({
  showInviteUser,
  setShowInviteUser,
  invitationDetails,
  setInvitationDetails,
  handleRoleChange,
  roleOptions,
  sendInvitation,
  isEmailValid,
  invitationError,
  invitationLoading,
  fetchInvitations,
}) => {
  const EmailUser = useSelector((state) => state.authSlice.user.email);
  const verified = useSelector((state) => state.authSlice.user.verified);
  const isSameEmail = invitationDetails.mail === EmailUser;
  console.log(
    invitationDetails.mail,
    EmailUser,
  'check_existance'
  );

  const isRoleSelected = invitationDetails.role_id !== "";
  const canSubmit = isEmailValid && verified && !isSameEmail && isRoleSelected;

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!canSubmit) {
      if (!verified) {
        toast.info("Your account needs to be verified to send invitations.");
      } else if (isSameEmail) {
        toast.info("You cannot send an invitation to your own email.");
      } else if (!isEmailValid) {
        toast.info("Please enter a valid email address.");
      } else if (!invitationDetails.role_id) {
        toast.info("Please select a role.");
      }
      return;
    }
    sendInvitation();
  };

  const handleCloseModal = () => {
    setShowInviteUser(false);
    setInvitationDetails({ mail: "", role_id: roleOptions[0]?.value }); // Resetting the invitation details to default
  };

  return (
    <div>
      {showInviteUser ? (
        <div
          onClick={(event) => {
            if (event.target === event.currentTarget) {
              handleCloseModal();
            }
          }}

          className="pointer-events-auto fixed inset-0 z-[1200] grid h-screen w-screen place-items-center
          bg-greeng 
          bg-opacity-60 opacity-100 backdrop-blur-sm transition-opacity duration-300"
        >
          <div className="w-full max-w-lg p-4 bg-white rounded-lg shadow">
            <h2 className="m-2 text-lg font-bold text-center text-white">Invite User</h2>

            <form onSubmit={handleSubmit} className="space-y-4">
              <input
                type="email"
                name="mail"
                placeholder="User Email"
                value={invitationDetails.mail}
                onChange={(e) =>
                  setInvitationDetails({
                    ...invitationDetails,
                    mail: e.target.value,
                  })
                }
                disabled={invitationLoading}
                className="w-full p-2 border rounded-lg focus:outline-none focus:border-gray-300 text-black"
                required
              />
              {!isEmailValid && (
                <p className="text-red-500">
                  Please enter a valid email address
                </p>
              )}
              {isSameEmail && invitationDetails.mail && (
                <p className="text-red-500">
                  You cannot send an invitation to your own email.
                </p>
              )}
              {invitationError && (
                <p className="text-red-500">{invitationError}</p>
              )}

              <Select
                options={roleOptions}
                value={roleOptions.find(
                  (option) => option.value === invitationDetails.role_id
                )}
                onChange={handleRoleChange}
                styles={customStyles}
                isDisabled={invitationLoading}
                placeholder="Select Role"
                required
              />
              {!isRoleSelected && (
                <p className="text-red-500">
                  Please select a role for the user
                </p>
              )}

              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={handleCloseModal}
                  className="px-4 py-2 text-white bg-gray-600 rounded-lg hover:bg-gray-700"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={invitationLoading || !canSubmit}
                  style={{
                    cursor:
                      invitationLoading || !canSubmit
                        ? "not-allowed"
                        : "pointer",
                    opacity: invitationLoading || !canSubmit ? 0.5 : 1,
                  }}
                  className="px-4 py-2 text-white bg-mainColor rounded-lg hover:bg-mainColor"
                >
                  {invitationLoading ? "Sending..." : "Send Invitation"}
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default InviteUserModal;
