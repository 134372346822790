import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const fetchData = createAsyncThunk(
  "data/fetchData",
  async ({ userId, projectId, selectedDate }, { getState }) => {
    
    const state = getState();
    const token = state.authSlice.token;
    // const formatedDate = selectedDate?.toISOString().split("T")[0];
    const url = `${process.env.REACT_APP_API_URL}/api/userRank/${userId}/${projectId}/`;
    console.log(url,"userId && projectIduserId && projectId");
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response,'responseresponse');
      
      return response.data; // This will be the `action.payload` in your extraReducers
    } catch (error) {
      throw error;
    }
  }
);

const tableSlice = createSlice({
  name: "table",
  initialState: {
    data: [],
    status: "idle", // Can be 'idle', 'loading', 'succeeded', 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload; // The data from the API response
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message; // The error from the API response
      });
  },
});

export default tableSlice.reducer;
