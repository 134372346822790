import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaUpload, FaSpinner } from "react-icons/fa";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import axios from "axios";
import chunkArray from "../../utils/Functions/ChunkArray";
import ConfirmationDialog from "../Modal/ConfirmingDialog";
import WarningModal from "../Modal/warningModal";

const FileUpload = ({ projectId, userId, setProcessCompleted, projectUrl, processComplete }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [warningDetails, setWarningDetails] = useState(null);
  const [retryChunkDetails, setRetryChunkDetails] = useState(null);
  const [allFlaggedQueries, setAllFlaggedQueries] = useState([]); // Collect all flagged queries
  const [allflag, setAllFlag] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formatTime = (timeString) => {
    const [time, modifier] = timeString.split(" ");
    let [hours, minutes, seconds] = time.split(":");
    if (modifier === "PM" && hours !== "12") {
      hours = String(parseInt(hours, 10) + 12);
    }
    if (modifier === "AM" && hours === "12") {
      hours = "00";
    }
    return `${hours}:${minutes}:${seconds}`;
  };

  const uploadFile = async (formattedChunk) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/add-rank-upload/${userId}/${projectId}/`,
        {
          queries_info: formattedChunk,
          // procedding_flag: proceedingFlag,
        },
        { headers: { "Content-Type": "application/json" } }
      );

      console.log("Upload response:", response);

      // Handle "warning" responses (e.g., URL_MISMATCH)
      // if (
      //   response.data &&
      //   response.data.status === "warning" &&
      //   response.data.code === "URL_MISMATCH"
      // ) {
      //   console.warn("Warning detected:", response.data);

      //   // Add flagged queries to the state
      //   setAllFlaggedQueries((prev) => [
      //     ...prev,
      //     ...response.data.queries_info,
      //   ]);

      //   // Set warning details for the modal
      //   setWarningDetails({
      //     description: response.data.detail || "A warning occurred. Please review.",
      //   });

      //   // Show warning dialog if not already open
      //   if (!isModalOpen) {
      //     setShowWarningDialog(true);
      //   }

      //   return { stopProcessing: true }; // Signal to stop further processing
      // }

      // Handle successful upload
      if (response && response.status === 201) {
        console.log("Chunk uploaded successfully");

        // Remove successfully uploaded queries from flagged list
        setAllFlaggedQueries((prev) => {
          const newQueries = prev.filter(
            (query) =>
              !formattedChunk.some((chunkQuery) => chunkQuery.query === query.query)
          );
          return newQueries;
        });

        return response; // Return success response
      }

      return response; // Return response for other cases
    } catch (err) {
      console.error("Error uploading chunk:", err);

      if (err.response) {
        console.error("Error Response:", err.response);

        // Handle specific error cases (e.g., URL_MISMATCH)
        if (err.response.status === 409 && err.response.data.code === "URL_MISMATCH") {
          setWarningDetails(err.response.data.detail);

          // Add flagged queries from error response
          setAllFlaggedQueries((prev) => [
            ...prev,
            ...err.response.data.queries_info,
          ]);

          // Show warning dialog
          if (!isModalOpen) {
            setShowWarningDialog(true);
          }

          return { stopProcessing: true }; // Signal to stop further processing
        } else {
          toast.error(`Error: ${err.response.data.message || "Unknown error"}`);
        }
      } else {
        // Handle network errors
        toast.error("Network error. Please check your connection.");
      }

      throw err; // Re-throw for retry or logging purposes
    }
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setAllFlaggedQueries([]); // Clear previous flagged queries
        setIsLoading(true); // Set loading state to true

        try {
          const data = await file.arrayBuffer();
          const workbook = XLSX.read(data, { type: "array" });
          const sheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(sheet);

          const chunkSize = 10;
          const chunks = chunkArray(jsonData, chunkSize);

          const totalChunks = chunks.length;
          let completedChunks = 0;
          let shouldStop = false; // Flag to stop further processing

          const formattedChunk2 = jsonData.map((rowData) => ({
            query: rowData.query || "",
            target_url: rowData.target_url || "",
            google_domain: rowData.google_domain || "",
            new_query: rowData.new_query || "nan",
            rank: rowData.rank || "",
            rank_abs: rowData.rank_abs || "",
            source_link: rowData.source_link || "",
            breadcrumb: rowData.breadcrumb || "",
            result_stat: rowData.result_stat || "",
            Type_rank: rowData.Type_rank || "",
            best_rank: rowData.best_rank || 0,
            rank_diff: rowData.rank_diff || 0,
            first_five_links: rowData.first_five_links || "nan",
            date: rowData.date ? formatDate(rowData.date) : "",
            time: rowData.time ? formatTime(rowData.time) : "",
          }));
          setAllFlag(formattedChunk2);

          for (const chunk of chunks) {
            if (shouldStop) break; // Exit loop if shouldStop is true

            const formattedChunk = chunk.map((rowData) => ({
              query: rowData.query || "",
              target_url: rowData.target_url || "",
              google_domain: rowData.google_domain || "",
              new_query: rowData.new_query || "nan",
              rank: rowData.rank || "",
              rank_abs: rowData.rank_abs || "",
              source_link: rowData.source_link || "",
              breadcrumb: rowData.breadcrumb || "",
              result_stat: rowData.result_stat || "",
              Type_rank: rowData.Type_rank || "",
              best_rank: rowData.best_rank || 0,
              rank_diff: rowData.rank_diff || 0,
              first_five_links: rowData.first_five_links || "nan",
              date: rowData.date ? formatDate(rowData.date) : "",
              time: rowData.time ? formatTime(rowData.time) : "",
            }));

            try {
              const response = await uploadFile(formattedChunk, false);

              if (response?.stopProcessing) {
                shouldStop = true; // Stop further processing
                break; // Exit the loop
              }

              if (response && response.status === 201) {
                setAllFlaggedQueries(formattedChunk);

                completedChunks++;
                setProgress(Math.round((completedChunks / totalChunks) * 100));
              }
            } catch (err) {
              console.error("Failed to upload chunk:", err);
            }
          }
        } catch (err) {
          console.error("Error processing file:", err);
          toast.error("Error processing file. Please try again.");
        } finally {
          setIsLoading(false); // Reset loading state
          setProgress(0); // Reset progress
          setProcessCompleted(!processComplete);
        }
      } else {
        toast.error("Please select a valid Excel file.");
      }
    },
    [projectId, userId]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
      "application/vnd.ms-excel": [".xls"],
    },
    maxFiles: 1,
  });

  const handleProceed = async () => {
    setIsModalOpen(true)
    // setShowWarningDialog(false);
    setIsLoading(true); // Set loading state to true

    try {
      if (allflag.length > 0) {
        console.log("Retrying upload with flagged queries:", allflag);

        const chunks = chunkArray(allflag, 10);
        let completedChunks = 0;
        const totalChunks = chunks.length;

        let updatedQueries = []; // Temporary array to store processed queries

        for (const chunk of chunks) {
          const response = await uploadFile(chunk, true); // Retry upload with proceeding_flag: true

          if (response && response.status === 201) {
            // Add successfully processed queries to updatedQueries
            updatedQueries = [...updatedQueries, ...chunk];

            // Update the UI dynamically with uploaded queries
            setAllFlaggedQueries((prev) => {
              const newQueries = prev.filter(
                (query) => !chunk.some((c) => c.query === query.query)
              ); // Remove uploaded queries from flagged list
              console.log("Remaining Flagged Queries:", newQueries);
              return newQueries;
            });
            completedChunks++;
            setProgress(Math.round((completedChunks / totalChunks) * 100));
          }
        }
        toast.success("Flagged queries uploaded successfully.");
        setAllFlaggedQueries([]);
        setProcessCompleted(!processComplete); // Mark process as completed
      }
    } catch (err) {
      console.error("Error during retry:", err);
      toast.error("Retry failed. Please check flagged queries.");
    } finally {
      // setProcessCompleted(!processComplete); // Mark process as completed
      setShowWarningDialog(false); // Close modal after action
      setIsLoading(false);
      setProgress(0);
    }
  };


  return (
    <div>
      {!isLoading && (
        <div
          {...getRootProps()}
          className="flex items-center">
          <input {...getInputProps()} />
          <div
            className={`flex items-center justify-center px-4 py-2 font-bold transition duration-150 ease-in-out bg-white rounded text-mainColor ${isLoading ? "cursor-not-allowed" : "hover:bg-mainColor hover:text-white"
              }`}
          >
            <FaUpload className="mr-2" />
            {isDragActive ? "Drop the file here ..." : "Import"}
          </div>
        </div>
      )}
      {isLoading && (
        <div className="flex items-center">
          <div className="flex items-center gap-2 w-full">
            <div className="w-[100px] h-2 bg-gray-200 rounded">
              <div
                className="h-full bg-green-500 rounded progress-bar-striped"
                style={{ width: `${progress}%` }}
              />
            </div>
            <div className="text-left font-semibold">{progress}%</div>
          </div>
          <div className="flex items-center justify-center px-4 py-2 font-bold transition duration-150 ease-in-out bg-white rounded text-mainColor">
            <FaSpinner className="mr-2 animate-spin" />
            Importing...
          </div>
        </div>
      )}
      <WarningModal
        isOpen={showWarningDialog}
        onClose={() => setShowWarningDialog(false)}
        onConfirm={() => { handleProceed(); setShowWarningDialog(false);}}
        warningDetails={warningDetails}
      />
    </div>
  );
};

export default FileUpload;
