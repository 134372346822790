import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logOut } from '../redux/lib/auth';

const CheckEmailPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  return (
    <div className='w-full h-screen flex items-center justify-center'>

      <div className="flex flex-col items-center 
    justify-center  mx-auto max-w-xl p-6
     rounded-lg shadow-custom h-[200px] mt-20 ">

        <h2 className="mb-4 text-2xl font-bold text-mainColor">
          Check Your Email</h2>
        <p className="mb-4 text-md text-center">
          We've sent a password reset link to your email.
          Please check your inbox to reset your password.</p>
        <button
          onClick={() => { dispatch(logOut()); navigate("/login") }}
          className="px-4 py-2 font-bold text-white rounded bg-mainColor hover:bg-mainColor-700"
        >
          Back to Login
        </button>
      </div>
    </div>
  );
};

export default CheckEmailPage;
