import React from "react";

const WarningModal = ({ isOpen, onClose, onConfirm, warningDetails }) => {
  if (!isOpen) return null;

  return (
    <div
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          onClose();
        }
      }}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30 backdrop-blur-sm"
      role="dialog"
      aria-labelledby="warning-modal-title"
      aria-modal="true"
    >
      <div className="w-full max-w-lg p-6 bg-white rounded-lg shadow-lg">
        <h2
          id="warning-modal-title"
          className="mb-4 text-xl font-bold text-red-600"
        >
          Warning
        </h2>
        <p className="mb-4 text-gray-800">
          {warningDetails?.description || "A warning has been detected. Please review the details below."}
        </p>
        <div className="flex justify-end space-x-4">
          <button
            className="px-4 py-2 font-bold text-black bg-gray-300 rounded hover:bg-gray-400"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 font-bold text-white bg-mainColor rounded "
            onClick={onConfirm}
          >
            Proceed Anyway
          </button>
        </div>
      </div>
    </div>
  );
};

export default WarningModal;
