import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function SimpleModal({
  isOpen,
  onClose,
  onProjectCreated,
  existingProjectNames,
  setPin_project,
  pin_project,
}) {
  const [projectName, setProjectName] = useState("");
  const [targetUrl, setTargetUrl] = useState("");
  const [domainName, setDomainName] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state
  const userId = useSelector((state) => state?.authSlice?.id);
  const [csrfToken, setCsrfToken] = useState("");

  useEffect(() => {
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    setCsrfToken(token);
  }, []);

  const resetForm = () => {
    setProjectName("");
    setTargetUrl("");
    setDomainName("");
    setError(""); // Reset error state as well
  };
  const sanitizeInput = (input,type) => {
    
    return type==="link"?input.replace(/[^a-zA-Z0-9 @.\-\/?:=&%\u0600-\u06FF]/g, ""):input.replace(/[^a-zA-Z0-9 @.\u0600-\u06FF]/g, ""); // Arabic characters range: \u0600-\u06FF
  };
  const handleInputChange = (event) => {
    const sanitizedValue = sanitizeInput(event.target.value,'name'); // Sanitize in real-time
    setProjectName(sanitizedValue); // Update form value with sanitized input
  };
 

  const extractDomain = (url) => {
    try {
      const urlObject = new URL(url);
      return urlObject.hostname.replace(/^www\./, "");
    } catch (error) {
      // If URL constructor fails, manually extract domain
      const domainPattern = /^(?:https?:\/\/)?(?:www\.)?([^\/]+)/i;
      const matches = url.match(domainPattern);
      return matches ? matches[1] : "";
    }
  };

  const handleCreation = async () => {
    if (existingProjectNames.includes(projectName.trim())) {
      setError("Project name already exists.");
      return;
    }
    if (!targetUrl) {
      setError("please add a target url");
      return;
    }

  

    let formattedUrl = targetUrl.trim();

    try {
      setLoading(true);
      const domain = extractDomain(formattedUrl);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/projects/${userId}/`,
        {
          name: projectName,
          url: domain,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Created");
      onProjectCreated(response.data);
      localStorage.setItem("projects", JSON.stringify(response.data));

      setPin_project(!pin_project);
      onClose();
      resetForm();
    } catch (error) {
      console.error(error.response || error.message);
      setError(error.response?.data?.message || "An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      resetForm(); // Reset form whenever the modal is opened
    }
  }, [isOpen, onClose]);

  const handleUrl = (e) => {
    const sanitizedValue = sanitizeInput(e.target.value,'link'); // Sanitize in real-time

    setTargetUrl(sanitizedValue);
    const domain = extractDomain(sanitizedValue);
    setDomainName(domain);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleCreation();
    }
  };

  if (!isOpen) return null;
  return (
    <div
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          onClose();
        }
      }}
      className="pointer-events-auto fixed inset-0 z-[1200] grid h-screen w-screen place-items-center bg-greeng bg-opacity-60 opacity-100 backdrop-blur-sm transition-opacity duration-300"
    >
      {/* Modal content */}
      <div className="relative mx-auto flex w-full max-w-[24rem] flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
        <div className="flex flex-col gap-4 p-6">
          {/* Input fields and Create Project button */}
          <input
            className="w-full h-full px-3 py-3 font-sans text-sm font-normal transition-all 
            bg-transparent border rounded-md  peer border-mainColor-gray-200 text-mainColor-gray-700 focus:outline-none
            focus:border-2 focus:border-basegray"
            placeholder="Project Name"
            value={projectName}
            onChange={(e) => handleInputChange(e)}
            onKeyDown={handleKeyDown} // Add onKeyDown event
          />
          <input
            className="w-full h-full px-3 py-3 
            font-sans text-sm 
            font-normal transition-all 
            bg-transparent border rounded-md 
            focus:outline-none peer 
            border-mainColor-gray-200 text-mainColor-gray-700 focus:border-2 focus:border-basegray"
            placeholder="Target URL"
            value={targetUrl}
            onChange={handleUrl}
            onKeyDown={handleKeyDown} // Add onKeyDown event
          />

          {error && <div className="text-red-500">{error}</div>}

          <div className="flex space-x-4">
            <button
              className={`block select-none rounded-lg w-[50%] bg-gradient-to-tr bg-red-500 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] ${
                loading ? "cursor-not-allowed" : ""
              }`}
              type="button"
              onClick={onClose}
              disabled={loading} // Disable the button while loading
            >
              Close
            </button>

            <button
              className={`block select-none rounded-lg w-[50%] bg-gradient-to-tr bg-mainColor py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] ${
                loading ? "cursor-not-allowed" : ""
              }`}
              type="button"
              onClick={handleCreation}
              disabled={loading} // Disable the button while loading
            >
              {loading ? "Creating..." : "Create Project"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SimpleModal;
