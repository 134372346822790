import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Using localStorage
import { encryptData, decryptData } from "../utils/crypto";
import authSlice from "./lib/auth";
import tableSlice from "./lib/fetchData";
import keywordSlice from "./lib/keyword";
import deleteRankSlice from "./lib/deleteRow";
import displayBookmarkSlice from "./lib/displayBookmarks";
import createBookmarkSlice from "./lib/createGroup";
import UrlSlice from "./lib/urlSearch";
import querySlice from "./lib/querySlice";
import keywordsingleSlice from "./lib/singlekeyword";
import dataSlice from "./lib/dataSlice";
import quoriesSlice from "./lib/quoriesSlice";
import favoritesSlice from "./lib/favoritesSlice";
import projectSlice from "./lib/projectSlice";
import rankSlice from "./lib/fetchRanks";
import addrankSlice from "./lib/addRank";
import deleteProjectSlice from "./lib/deleteProject";
import errorSlice from "./lib/errorSlice";
import deleteProjectBulkSlice from "./lib/deleteBulkProjects";
import roleSlice from "./lib/roleSlice";
import originalUserSlice from "./lib/originalUserSlice";
import projectCompetitors from "./lib/projectCompetitors";

const rootReducer = combineReducers({
  authSlice,
  tableSlice,
  keywordSlice,
  deleteRankSlice,
  deleteProjectSlice,
  displayBookmarkSlice,
  createBookmarkSlice,
  UrlSlice,
  querySlice,
  keywordsingleSlice,
  dataSlice,
  quoriesSlice,
  favoritesSlice,
  projectSlice,
  rankSlice,
  addrankSlice,
  errorSlice,
  deleteProjectBulkSlice,
  roleSlice,
  originalUserSlice,
  projectCompetitors,
  
});

const encryptTransform = {
  in: (state) => encryptData(state),
  out: (state) => decryptData(state),
};

const persistConfig = {
  key: "root",
  storage, // Use localStorage here
  transforms: [encryptTransform],
  whitelist: [
    "authSlice",
    "roleSlice",
    "projectCompetitors",
    "originalUserSlice",
  ], // Whitelist only necessary slices
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: getDefaultMiddleware({
//     serializableCheck: {
//       ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
//     },
//   }),
// });
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };
