import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const getCsrfToken = () => {
  return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
};
export const fetchProjects = createAsyncThunk(
  'data/fetchprojects',
  async ({ userId }, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.authSlice.token;
    const csrfToken = getCsrfToken(); // Get the CSRF token

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Displayprojects/${userId}/`, {
        headers: {
          Authorization: `Bearer ${token}`, 

        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const togglePinProject = createAsyncThunk('projects/togglePinProject', async ({ userId, projectId }, { getState, requestId }) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/toggle_project_flag/${userId}/${projectId}/`);
  return response.data; // Assuming this is the updated pin status
});
const initialState = {
  projects: [],
  pinnedProjectId: null,
  loading: false,
  error: null,
};

export const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    // Reducer to set a pinned project ID
    setPinnedProjectId: (state, action) => {
      state.pinnedProjectId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.projects = action.payload.map(project => ({
          ...project,
          isPinned: project.flag, // Assuming 'flag' indicates if the project is pinned
        }));
        state.loading = false;
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });


      builder
  .addCase(togglePinProject.pending, (state) => {
    state.status = 'loading';
  })
  .addCase(togglePinProject.fulfilled, (state, action) => {
    const { projectId, isPinned } = action.meta.arg;
    const index = state.projects.findIndex(project => project.project_id === projectId);
    if (index !== -1) {
      // Reset all projects to not pinned
      state.projects.forEach(project => {
        project.isPinned = false;
      });
      // Set the toggled project's pin status
      state.projects[index].isPinned = isPinned;
      // Optionally sort or manipulate the projects array as needed
    }
    state.status = 'succeeded';
  })
  .addCase(togglePinProject.rejected, (state, action) => {
    state.status = 'failed';
    state.error = action.error.message;
  });
  },
  
});

export const { setPinnedProjectId } = projectSlice.actions;

export default projectSlice.reducer;



