import React, { useState, useEffect, useCallback } from "react";
import useKeywordAndUrlProcessing from "../../../hook/useKeywordAndUrlProcessing";
import { useDispatch, useSelector } from "react-redux";
import Title from "../../../pages/SidebarPages/MainKeyword/keyword/Layout/Title";
import Tables from "../../../pages/SidebarPages/MainKeyword/keyword/Layout/Tables";

import InputForm from "./InputForm";
import SkeletonMainKeywordInfo from "../../Skeleton/SkeletonMainKeywordInfo";
import { useNavigate } from "react-router-dom";
import { jsonrepair } from "jsonrepair";
import axios from "axios";
import { toast } from "react-toastify";
import { FaEye, FaSpinner, FaTrash, FaRegFileWord } from "react-icons/fa"; // Import the Word icon
import htmlDocx from "html-docx-js/dist/html-docx";
import { saveAs } from "file-saver";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import StepForm from "./StepForm";
import LinkModal from "../../Modal/LinkModal";
import { FaInfoCircle } from "react-icons/fa";
import MainKeywordInfo from "../../../pages/SidebarPages/MainKeyword/keyword/KeywordInfo/MainKeywordInfo";

const ResultSample = ({ data, IsLocation }) => {
  const userId = useSelector((state) => state?.authSlice?.user.id);
  const [selectedData, setSelectedData] = useState(data);
  const [responseSearchData, setResponseSearchData] = useState();
  const [searchData, setSearchData] = useState(false);
  const [close_refresh, set_close_refresh] = useState(false);

  const dispatch = useDispatch();
  const {
    handleSubmit,
    isKeywordLoading,
    isUrlLoading,
    keywordData,
    urlData,
    links,
    closeModal,
    modalVisible,
    setModalVisible,
    modalMessage,
    refreshLinks,
    selectedrefreshLinks,
    setSelectedRefreshLinks,
    isConfirmationrefreshOpen,
    setIsConfirmationRefreshOpen,
    isRefreshing,
    setIsRefreshing,
    setIsRefreshLoading,
    refreshloading,
    setIsRefreshingClose,
    isRefreshingClose,
    button_refresh,
    set_button_refresh
  } = useKeywordAndUrlProcessing(dispatch, userId, data);
  const [sortDirection, setSortDirection] = useState(null);
  const token = useSelector((state) => state.authSlice?.user.token);
  const [isGenerating, setIsGenerating] = useState(false); // Track loading state for generating articles
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null); // Track item to delete
  const [newHeadings, setNewHeadings] = useState([]);
  const [oldHeadings, setOldHeadings] = useState([]);
  const [resultSample, setResultSample] = useState(null);
  const [isModal_Open, setIsModal_Open] = useState(false);
  const [minCount, setMinCount] = useState(0); // Initialize minCount state
  const [maxCount, setMaxCount] = useState(1000); // Initialize maxCount state
  const [art_id, set_art_id] = useState(); // Initialize maxCount state
  const [averageWordCount, setAverageWordCount] = useState(0);
  const [wordCounts, setWordCounts] = useState([]);
  const [advancedOption, setAdvancedOption] = useState(false)
  const [remainingGenerating, setRemainingGenerating] = useState()
  const [quota, setQuota] = useState()
  const [ut_id, set_ut_id] = useState(); // Initialize maxCount state

  const url_title = data[0].query;
  const fetchQouta = useCallback(async () => {

    await axios.get(`${process.env.REACT_APP_API_URL}/api/UserProfileQuote/${userId}/`).then((res) => {
      console.log(res.data, 'res.data');
      setRemainingGenerating(res.data.daily_quota_AI)
      setQuota(res.data)

    })


  }, []);
  useEffect(() => {
    fetchQouta()

  }, [fetchQouta])
  const close_Modal = () => {
    setIsModal_Open(false); // Set the state to false to close the modal
  };
  const close__Modal = () => {
    setIsHistoryModalOpen(false); // Close the history modal
  };

  const handleDeleteClick = (item) => {
    setItemToDelete(item);
    setIsConfirmationModalOpen(true); // Open confirmation modal
  };

  const handleViewDetails = async (item) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/keyword/generated-articles/${item.art_id}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const { Generated_article, new_headings, user, word_count, title } =
        response.data;

      if (Generated_article && new_headings && word_count && user) {
        navigate(`/generatedarticle/${user}/${item.art_id}/`, {
          state: {
            content: Generated_article,
            headings: new_headings, // Use new_headings from response data
            url_title: title || item.title, // Use title from response data if available, otherwise use item.title
            fromHistory: true,
            word_count,
          },
        });
      } else {
        console.error("Missing required data for navigation.");
      }
    } catch (error) {
      console.error("Error fetching article details:", error);
    }
  };



  const navigate = useNavigate();
  let jsonString;
  let parsedResultsSample;
  let Query;
  let aggregated_results;
  let aggregated_h2_counts;
  let aggregated_h3_counts;
  let groupedAndSortedKeywords;
  let sortedData;
  let filteredLinks;
  let word_count;

  let allLinks;

  const repaired = selectedData?.[0]?.selected_results_sample && jsonrepair(selectedData?.[0]?.selected_results_sample);
  console.log(jsonrepair(selectedData?.[0]?.results_sample), 'jsonrepair(selectedData?.[0]?.results_sample)');

  allLinks = JSON.parse(jsonrepair(selectedData?.[0]?.results_sample));
  allLinks = allLinks?.filtered_links;
  jsonString = repaired;
  parsedResultsSample = JSON.parse(jsonString);
  aggregated_results =
    selectedData[0]?.rank_avg !== ""
      ? JSON.parse(jsonrepair(selectedData[0]?.rank_avg))
      : "";
  aggregated_h2_counts =
    selectedData[0]?.h2_count !== ""
      ? JSON.parse(jsonrepair(selectedData[0]?.h2_count))
      : "";
  aggregated_h3_counts =
    selectedData[0]?.h3_count !== ""
      ? JSON.parse(jsonrepair(selectedData[0]?.h3_count))
      : "";

  word_count = selectedData[0]?.word_count || [];

  if (word_count.length > 0) {
    const totalWords = word_count.reduce((sum, count) => sum + count, 0);
    const averageWordCount = totalWords / word_count.length;

    // Log the average word count
  }
  sortedData = aggregated_results;
  Query = selectedData[0].query;

  filteredLinks = parsedResultsSample?.filtered_links;
  console.log(filteredLinks, "filteredLinks")
  console.log(selectedData, parsedResultsSample, 'selectedData');


  const extractDomainWithPath = (url) => {
    try {
      const urlObj = new URL(url);
      return `${urlObj.hostname}${urlObj.pathname}`; // Include path for unique identification
    } catch (error) {
      console.error("Invalid URL:", url);
      return null;
    }
  };



  // Update ranks in filteredLinks based on matching domains in allLinks
  const updatedFilteredLinks = filteredLinks?.map((link) => {
    const linkIdentifier = extractDomainWithPath(link.link); // Use domain + path
    const matchingAllLink = allLinks.find(
      (allLink) => extractDomainWithPath(allLink.link) === linkIdentifier
    );

    return {
      ...link,
      rank: matchingAllLink ? matchingAllLink.rank : link.rank, // Update rank from allLinks if a match is found
    };
  });
  // Sorting updatedFilteredLinks by rank
  const sortedUpdatedFilteredLinks = updatedFilteredLinks?.sort((a, b) => a.rank - b.rank);

  useEffect(() => {
    if (responseSearchData) {
      setSelectedData([responseSearchData]);
    }
  }, [searchData]);
  function findCommonPhrase(keyword, groups) {
    for (let phrase in groups) {
      const wordsInGroup = phrase.split(" ");
      const wordsInKeyword = keyword.split(" ");
      // Ensure at least 2 words match, and prioritize more than 2-word phrases
      const matchCount = wordsInGroup.filter((word) =>
        wordsInKeyword.includes(word)
      ).length;
      if (matchCount >= 2) {
        return phrase;
      }
    }
    return null;
  }
  function groupAndSortKeywordsByPhrases(keywords) {
    const groups = {}; // Store groups with the original object structure
    keywords.forEach((item) => {
      const foundGroup = findCommonPhrase(item.keyword, groups);
      if (foundGroup) {
        groups[foundGroup].push(item);
      } else {
        // Create a new group based on the first two words (or more) as a phrase
        const phrase = item.keyword.split(" ").slice(0, 3).join(" "); // Adjust this to capture 2-3 word phrases
        if (!groups[phrase]) {
          groups[phrase] = [];
        }
        groups[phrase].push(item);
      }
    });
    // Convert groups to an array of objects and sort them by total count in the group
    const sortedGroups = Object.entries(groups).map(([group, items]) => ({
      group,
      totalCount: items.reduce((acc, curr) => acc + curr.count, 0), // Sum counts
      // Sort the items within each group by count in descending order
      items: items.sort((a, b) => b.count - a.count),
    }));
    const flattenedKeywords = sortedGroups.flatMap((group) => group.items);
    // Sort the final array so that items with only one keyword go to the end
    return flattenedKeywords.sort((a, b) => {
      const aWords = a.keyword.split(" ").length;
      const bWords = b.keyword.split(" ").length;
      // Push items with only one word to the end
      if (aWords === 1 && bWords > 1) return 1;
      if (bWords === 1 && aWords > 1) return -1;
      // Otherwise, maintain original order
      return 0;
    });
  }
  let combinedH2H3DataArray = [];


  combinedH2H3DataArray = [
    ...(aggregated_h2_counts ?
      //  Object.entries(aggregated_h2_counts).flatMap(([outerKey, counts]) =>

      Object.entries(aggregated_h2_counts).map(([keyword, count]) => ({
        keyword: keyword,
        count:
          typeof count === "object" ? count[Object.keys(count)[0]] : count, // Extract count if nested
        type: "H2",
      }))
      // )
      : []
    )
    ,
    ...(aggregated_h3_counts ?
      //  Object.entries(aggregated_h3_counts).flatMap(([outerKey, counts]) =>
      Object.entries(aggregated_h3_counts).map(([keyword, count]) => ({
        keyword: keyword,
        count:
          typeof count === "object" ? count[Object.keys(count)[0]] : count, // Extract count if nested
        type: "H3",
      }))
      // )
      : []),
  ];






  const handleSort = () => {
    let sorted;
    let newSortDirection;
    if (sortDirection === "asc") {
      sorted = [...aggregated_results].sort((a, b) => b.count - a.count);
      newSortDirection = "desc";
    } else if (sortDirection === "desc") {
      sorted = [...aggregated_results].sort((a, b) => a.count - b.count);
      newSortDirection = null;
    } else {
      sorted = [...aggregated_results];
      newSortDirection = "asc";
    }
    sortedData = sorted;
    setSortDirection(newSortDirection);
  };
  const aggregatedResultsColumns = [
    {
      name: "Keyword",
      selector: (row) => row.keyword,
      sortable: true,
      width: "85%",
    },
    {
      name: "Count",
      selector: (row) => Math.round(row.count),
      sortable: true,
      width: "15%",
    },
  ];
  const combinedH2H3Columns = [
    {
      name: "Heading",
      selector: (row) => row.keyword,
      sortable: true,
      width: "70%",
    },
    {
      name: "Count",
      selector: (row) => Math.round(row.count),
      sortable: true,
      width: "15%",
    },
    { name: "Type", selector: (row) => row.type, sortable: true, width: "15%" },
  ];
  const handleHistory = async () => {
    try {
      setIsLoadingHistory(true);
      setIsHistoryModalOpen(true); // Show modal
      // Fetch data whenever the modal is opened
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/keyword/generated-articles/`,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // Filter out entries where kwd_flag is true
      let filteredData = response.data.filter(
        (item) => item.kwd_flag === true
      );
      filteredData = response.data.filter(
        (item) => item.Generated_article !== ""
      );
      // Sort filtered data by date from newest to oldest
      const sortedData = filteredData.sort(
        (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)
      );
      setHistoryData(sortedData);
    } catch (error) {
      console.error("Error fetching history:", error);
    } finally {
      setIsLoadingHistory(false);
    }
  };
  const handleGenerateArticle = async () => {
    const keywordId = data[0].object_id;
    if (data) {
      if (keywordId) {
        try {
          setIsGenerating(true); // Start loading
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/keyword/generated-articles/`,
            { title: url_title, kwd_id: keywordId }, // Using the new API body structure
            {
              headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          console.log(response.data, 'responseresponse');
          if (response.data.length > 0) {
            // Display the daily quota message as a toast
            response.data.forEach(value => {
              toast.error(value);
            });
            return; // Stop further processing if quota message is received
          }
          if (response.data && !Array.isArray(response.data)) {
            const {
              new_headings,
              old_headings,
              result_sample,
              min_count,
              max_count,
              art_id,
              ut_id
            } = response.data;
            console.log(response.data,"kfkffkkk")
            setNewHeadings(new_headings || []);
            setOldHeadings(old_headings || []);
            setMinCount(min_count || 0);
            setMaxCount(max_count || 1000);
            setResultSample(result_sample);
            set_art_id(art_id);
            set_ut_id(ut_id);
            console.log(ut_id,"ut_id")

            const calculatedWordCount = min_count && max_count ? Math.floor((min_count + max_count) / 2) : 1000;

            // Parse and update usedKeywords state
            if (advancedOption) {
              setIsModal_Open(true);

            } else {

              const payload = {
                article_id: art_id,
                // word_count: 1000,
                // word_count: calculatedWordCount,
                new_headings: new_headings ? new_headings : undefined,
              };
              navigate(`/generatedarticle/${userId}/${art_id}/`, {
                state: {
                  payload,
                },
              });

            }
          }
        } catch (error) {
          console.error("Error generating article:", error);
          toast.error("Failed to generate article");
        } finally {
          setIsGenerating(false); // Stop loading
        }
      }
    }
  };


  const [loadingMessage, setLoadingMessage] = useState(
    "Please wait, your article is being generated. Do not refresh or close this page."
  );
  useEffect(() => {
    if (isGenerating) {
      const firstTimeout = setTimeout(() => {
        setLoadingMessage("Still loading... Please hold on for a moment.");
      }, 30000); // 30 seconds
      const secondTimeout = setTimeout(() => {
        setLoadingMessage(
          "Please be patient, the process is taking a bit longer than expected."
        );
      }, 60000); // 60 seconds (30 more seconds from the previous message)
      return () => {
        clearTimeout(firstTimeout);
        clearTimeout(secondTimeout);
      };
    }
  }, [isGenerating]);

  const handleExport = (item) => {
    const content = item.Generated_article;
    const title = item.title;

    if (content) {
      const converted = htmlDocx.asBlob(content);
      saveAs(converted, `${title}.docx`);
      toast.success("Article exported as Word");
    } else {
      toast.error("No content available to export.");
    }
  };

  const handleConfirmDelete = async () => {
    if (itemToDelete) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/keyword/generated-articles/${itemToDelete.art_id}/`,
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setHistoryData((prevData) =>
          prevData.filter((item) => item.art_id !== itemToDelete.art_id)
        );
        toast.success("Article deleted successfully");
      } catch (error) {
        console.error("Error deleting article:", error);
      } finally {
        setIsConfirmationModalOpen(false); // Close confirmation modal
        setItemToDelete(null); // Clear the item to delete
      }
    }
  };

  const handleLinksSelection = (responseData) => {
    if (responseData.word_count && Array.isArray(responseData.word_count)) {
      const wordCountsArray = responseData.word_count;
      const totalWords = wordCountsArray.reduce((sum, count) => sum + count, 0);
      const calculatedAverageWordCount = wordCountsArray.length > 0 ? totalWords / wordCountsArray.length : 0;

      // Set the calculated average to the state
      setAverageWordCount(calculatedAverageWordCount);

      // Log the calculated average for debugging
      console.log("Average Word Count:", calculatedAverageWordCount);
    }
    console.log(responseData, "responseData")
    setSearchData(!searchData);
    navigate(
      `/detailskeyword/${responseData.query}`,
      { replace: true },
      { state: { responseData: responseData } }
    );
    // window.location.reload();
    setResponseSearchData(responseData);
    setModalVisible(false);
  };
  useEffect(() => {
    if (data && data[0]?.word_count) {
      // Get the word count array
      const wordCountsArray = data[0].word_count;
      setWordCounts(wordCountsArray);

      // Calculate the average word count
      const total = wordCountsArray.reduce((sum, count) => sum + count, 0);
      const average =
        wordCountsArray.length > 0 ? total / wordCountsArray.length : 0;
      setAverageWordCount(average);
    }
  }, [data]);
  useEffect(() => {
    if (data && data[0]) {
      const { h2_count, h3_count, rank_avg } = data[0];
      if (h2_count === "" && h3_count === "" && rank_avg === "") {
        setModalVisible(true);
        setIsRefreshingClose(true);
        set_close_refresh(true)
        setSelectedRefreshLinks(true)
      }
    }
  }, [data]);
  return (
    <div
      className="relative w-full mx-auto mt-[5rem] rounded-lg
   bg-baseColor dark:bg-darkbg dark:text-gray-300 md:mt-14 md:p-5"
    >
      <ConfirmationModal
        show={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}

        onConfirm={handleConfirmDelete}
        title={itemToDelete?.title}
      />
      {modalVisible && (
        <>
          <LinkModal
            set_button_refresh={set_button_refresh}
            isOpen={modalVisible}
            set_close_refresh={set_close_refresh}
            close_refresh={close_refresh}
            button_refresh={button_refresh}
            onClose={() => {
              setModalVisible(false);
            }}
            links={links.length > 0 ? links : allLinks}
            // filteredLinks={modalVisible ? null : filteredLinks}

            filteredLinks={filteredLinks}
            query={keywordData ? keywordData.Query : Query}
            onSelectionComplete={(selected) => {
              handleLinksSelection(selected);
            }}
            refreshLinks={() => { refreshLinks(keywordData ? keywordData.Query : Query) }}
            message={modalMessage}
            selectedrefreshLinks={selectedrefreshLinks}
            setSelectedRefreshLinks={setSelectedRefreshLinks}
            isConfirmationrefreshOpen={isConfirmationrefreshOpen}
            isRefreshing={isRefreshing}
            setIsRefreshing={setIsRefreshing}
            setIsConfirmationRefreshOpen={setIsConfirmationRefreshOpen}
            setIsRefreshLoading={setIsRefreshLoading}
            refreshloading={refreshloading}
            setIsRefreshingClose={setIsRefreshingClose}
            isRefreshingClose={isRefreshingClose}

          />
        </>
      )}

      {((!isKeywordLoading && !isUrlLoading) || selectedrefreshLinks) && (
        <div className="flex justify-end gap-4 mb-5">
          <div className={`flex flex-col items-center  justify-center`}>
            <div className=" flex space-x-7 items-center ps-4">

              <div className="flex items-center justify-between mt-4 p-4 space-x-4  w-full max-w-xl mx-auto">
                {/* Quota Display */}
                <p
                  className={`text-sm font-medium ${quota?.daily_quota_AI === 0 ? "text-red-800" : "text-mainColor"
                    }`}
                >
                  <span>
                    {quota?.daily_quota_AI}/
                    {quota?.billing_type === 1
                      ? "1000"
                      : quota?.billing_type === 2
                        ? "500"
                        : quota?.billing_type === 3
                          ? "250"
                          : quota?.billing_type === 10
                            ? "20000"
                            : ""}{" "}
                  </span>
                  <span className="block text-mainColor">Remaining Keywords</span>
                </p>

                {/* Button with Toggle */}
                <div
                  className="flex items-center gap-4 bg-gradient-to-r from-sky-500 to-sky-300 text-white px-3 py-3 rounded-full shadow-md transform transition-transform duration-200 hover:scale-105 cursor-pointer"
                >
                 
                   {isGenerating ? (
    <div className="flex items-center justify-center gap-2">
      <FaSpinner className="animate-spin text-lg" /> {/* Spinner */}
      <span>Generating...</span>
    </div>
  ) : (
    <div className="flex items-center justify-center gap-2">
      <span                     onClick={handleGenerateArticle}
      >Generate Article</span>
    </div>
  )}

                  {/* Toggle Switch */}
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={advancedOption}
                      onChange={() => setAdvancedOption(!advancedOption)}
                      className="sr-only"
                    />
                    {/* <div className="w-10 h-5 bg-white rounded-full relative shadow-inner">
        <div
          className={`w-4 h-4 bg-gradient-to-r from-sky-500 to-sky-300 rounded-full absolute top-[2px] left-[2px] transition-transform ${
            advancedOption ? "translate-x-5" : ""
          }`}
        ></div>
      </div> */}
                    <div
                      className={`w-10 h-5 rounded-full relative shadow-inner transition-colors duration-300 ${advancedOption ? "bg-gray-100" : "bg-blue-200"
                        }`}
                    >
                      <div
                        className={`w-4 h-4 bg-gradient-to-r from rounded-full absolute top-[2px] left-[2px] transition-transform ${advancedOption ? "translate-x-5 bg-white" : "bg-gradient-to-r from-sky-500 to-sky-300"
                          }`}
                      ></div>
                    </div>
                  </label>
                  <span onClick={() => setAdvancedOption(!advancedOption)}
                  >Pro</span>

                </div>
                <button
                  onClick={handleHistory}
                  className="flex 
            items-center gap-2 bg-gradient-to-r from-gray-500 to-gray-300
               text-white px-4 py-2 rounded-full shadow-lg
               transform transition duration-200 ease-in-out hover:scale-105 h-fit"
                >
                  Show History
                </button>
              </div>





            </div>









          </div>

          {/* </div> */}




        </div>





      )}

      <StepForm
        isOpen={isModal_Open}
        onClose={close_Modal}
        newHeadings={newHeadings}
        oldHeadings={oldHeadings}
        resultSample={resultSample}
        minCount={minCount}
        maxCount={maxCount}
        art_id={art_id}
        ut_id={ut_id}

      />

      {isHistoryModalOpen && (
        <div
          onClick={(event) => {
            if (event.target === event.currentTarget) {
              close__Modal();
            }
          }}

          className="  pointer-events-auto fixed inset-0 
z-[1200] grid h-screen w-screen place-items-center
 bg-greeng 
 bg-opacity-60 opacity-100 backdrop-blur-sm transition-opacity duration-300"
        >
          <div
            className="bg-white rounded-lg p-6 w-[50%]
           max-h-[60vh] overflow-auto shadow-lg border border-gray-300"
          >
            <h2 className="text-xl font-bold mb-4 pb-2 border-b border-gray-300">
              Article History Keyword
            </h2>
            {isLoadingHistory ? (
              <div className="flex justify-center items-center">
                <FaSpinner className="animate-spin text-2xl" />
              </div>
            ) : historyData.length > 0 ? (
              <ul className="space-y-4">
                {historyData
                  .sort(
                    (a, b) =>
                      Date.parse(b.created_at) - Date.parse(a.created_at)
                  ) // Ensuring proper date parsing
                  .map((item) => (
                    <li
                      key={item.object_id}
                      className="border-b pb-2 cursor-pointer flex justify-between items-center"
                    >
                      <div>
                        <h3
                          onClick={() => handleViewDetails(item)}
                          className="font-semibold"
                        >
                          {item.title}
                        </h3>
                        <p className="text-sm text-gray-500">
                          Created At:{" "}
                          {new Date(item.created_at).toLocaleString()}
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => handleViewDetails(item)}
                          className="text-mainColor px-2 py-2 rounded-md transition duration-200"
                          title="View Details"
                        >
                          <FaEye />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(item)} // Use the new delete click handler
                          className="text-red-500 px-2 py-2 rounded-md transition duration-200"
                          title="Delete Article"
                        >
                          <FaTrash />
                        </button>
                        <button
                          onClick={() => handleExport(item)}
                          className="text-blue-500 px-2 py-2 rounded-md transition duration-200"
                          title="Export Article as Word"
                        >
                          <FaRegFileWord />
                        </button>
                      </div>
                    </li>
                  ))}
              </ul>
            ) : (
              <p>No history found.</p>
            )}
            <button
              onClick={close__Modal}
              className="flex mt-3 ml-auto px-4 py-2 font-bold text-black
               bg-[#D1D5DB] rounded "
            >
              Close
            </button>
          </div>
        </div>
      )}
      <div
        className={`items-center w-full ${(parsedResultsSample || urlData) &&
          "grid grid-cols-1   2xl:grid-cols-5 md:gap-6 gap-3"
          }`}
      >
        <div className="lg:col-span-3">
          <Title title="Main Keyword Research" />
          <InputForm
            onSubmit={handleSubmit}
            isLoading={isKeywordLoading || isUrlLoading}
          />
        </div>
        {isKeywordLoading && !selectedrefreshLinks ? (
          <SkeletonMainKeywordInfo />
        ) : (
          <MainKeywordInfo
            url={urlData ? true : false}
            keyword={Query}
            wordCount={averageWordCount.toFixed()}
            optimizationScore={75}
          />
        )}
      </div>
      {data || keywordData ? (
        <Tables

          selectedrefreshLinks={selectedrefreshLinks}
          allLinks={allLinks}
          linksData={sortedUpdatedFilteredLinks}
          aggregatedResults={sortedData}
          aggregatedResultsColumns={aggregatedResultsColumns}
          combinedH2H3DataArray={combinedH2H3DataArray}
          combinedH2H3Columns={combinedH2H3Columns}
          onSort={handleSort}
          Query={Query}
          setModalVisible={setModalVisible}
          isKeywordLoading={isKeywordLoading}
          onSelectionComplete={(selected) => {
            handleLinksSelection(selected);
          }}
        />
      ) : (
        <div className=" lg:w-[80%] flex items-center justify-center py-8 border border-gray-300 rounded m-auto my-8">
          <p className="font-medium">No data available</p>
        </div>
      )}
    </div>
  );
};
export default ResultSample;
