import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../redux/lib/fetchData";
import { fetchFavorites } from "../redux/lib/favoritesSlice";
import { createSelector } from "reselect";
// Input selectors
const selectTableDataInput = (state) => state.tableSlice.data.results;
const selectDetailDataInput = (state) => state.favoritesSlice.favorites;
const selectCompetitorInput = (state) => state.tableSlice.data.competitor;

// Memoized selectors with transformation logic
const selectTableData = createSelector(
  [selectTableDataInput],
  (results) => ({ results: results || [] }) // Wrap in an object to ensure new reference
);

const selectDetailData = createSelector(
  [selectDetailDataInput],
  (favorites) => ({ favorites: favorites || [] }) // Wrap in an object to ensure new reference
);

const selectCompetitor = createSelector(
  [selectCompetitorInput],
  (competitor) => ({ competitor: competitor || null }) // Wrap in an object to ensure new reference
);

const useFetchData = (
  userId,
  projectId,
  selectedBookmarkId,
  processComplete,
  selectedDate,
  isDelete
) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [is_Loading, setIs_Loading] = useState(true);
  const { results: tableData } = useSelector(selectTableData);
  const { favorites: detailData } = useSelector(selectDetailData);
  const { competitor } = useSelector(selectCompetitor);
  const [notFound, setNotFound] = useState(false); // New state to handle 404

  const handleStorageError = (error) => {
    if (error.name === "QuotaExceededError") {
      console.warn("Local storage quota exceeded. Clearing local storage.");
      localStorage.clear();
    } else {
      console.error("Error accessing local storage", error);
    }
  };

  const fetchDataCallback = useCallback(() => {
    console.log(selectedDate, "selectedDateselectedDate");
    if (userId && projectId) {
      console.log(userId , projectId,'userId && projectId');
      
      setLoading(true);
      dispatch(fetchData({ userId, projectId, selectedDate }))
        // .unwrap()
        .then(() => {
          setLoading(false);
          setIs_Loading(false);
          setNotFound(false); // Reset 404 state on success

        })
        .catch((error) => {
          setLoading(false);
          setIs_Loading(false);
          handleStorageError(error);


          setNotFound(true); // Set 404 state if project not found

        });
    }
  }, [dispatch, userId, projectId, selectedDate]);

  const fetchFavoritesCallback = useCallback(() => {
    if (userId && selectedBookmarkId) {
      dispatch(
        fetchFavorites({ userId, bookmarkId: selectedBookmarkId })
      ).catch(handleStorageError);
    }
  }, [dispatch, userId, selectedBookmarkId]);

  useEffect(() => {
    console.log("fetchDataCallback", {
      fetchDataCallback,
      processComplete,
    });
    fetchDataCallback();
  }, [fetchDataCallback, processComplete]);

  useEffect(() => {
    fetchFavoritesCallback();
  }, [fetchFavoritesCallback]);
  console.log(tableData, "tableData");

  return { loading, tableData, detailData, competitor, is_Loading, notFound };
};

export default useFetchData;
