import React, { useState, useEffect } from "react";
import axios from "axios";
import { FiPlus } from "react-icons/fi";
import SimpleModal from "../../../components/Modal/SimpleModal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PagePaginationProjects from "../../../components/MainRank/Pagination/PagePaginationProjects";
import ProjectCardList from "./ProjectCardList";
import { extractDomainName, formatDate, sortCards } from "../../../utils/utils";
import PinReplaceModal from "../../../components/Modal/PinReplaceModal";

const Projects = ({
  activeProject,
  setActiveProject,
  topThreeProjects,
  setTopThreeProjects,
  pin_project,
  setPin_project,
  getTopThreePinnedProjects,

}) => {
  console.log(topThreeProjects, activeProject, "topThreeProjects");
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [cards, setCards] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const userId = useSelector((state) => state?.authSlice?.id);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [projectIdToDelete, setProjectIdToDelete] = useState(null);
  const [loadingCharts, setLoadingCharts] = useState({}); // Define loadingCharts state here
  const [chartSeries, setChartSeries] = useState({});

  const [csrfToken, setCsrfToken] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 6;

  const chartOptions = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      height: 350,
      toolbar: {
        show: false,
      },
    },
    colors: ["#2BA950"],
    xaxis: {
      type: "datetime",
      labels: {
        format: "dd MMM",
      },
    },
    yaxis: {
      reversed: true,
      labels: {
        formatter: (value) => Math.round(value),
      },
      title: {
        text: "Average Rank",
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    markers: {
      size: 5,
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
      y: {
        formatter: (value) => `Rank: ${Math.round(value)}`,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (value) => value.toFixed(1),
    },
  };
  const [showPinModal, setShowPinModal] = useState(false);
  const [projectToPin, setProjectToPin] = useState(null);
  const [projectToPinName, setProjectToPinName] = useState(null);

  const [pinnedProjects, setPinnedProjects] = useState([]); // To store the top three pinned projects
  const [loadingPin, setLoadingPin] = useState(false);
  const handleReplacePin = async (projectIdToUnpin) => {
    setLoadingPin(true);

    // Unpin the selected project
    await togglePinProject(projectToPinName, projectIdToUnpin, true, false);

    // Automatically pin the new project after unpinning
    await togglePinProject(projectToPinName, projectToPin, true, true);
    await getTopThreePinnedProjects()
    setTimeout(() => {
      setLoadingPin(false);
      // Close the modal
      setShowPinModal(false);
      setProjectToPin(null);
    }, [1000]);
  };

  const roles = useSelector((state) => state.roleSlice.roles);
  const managed = useSelector((state) => state.authSlice.managed);
  const verfied = useSelector((state) => state?.authSlice?.user?.verified);
  const EmailUser = useSelector((state) => state?.authSlice?.user?.email);
  let currentProjects;
  const navigate = useNavigate();
  const notManage = verfied && !managed;
  const manageRoles = verfied && managed && roles.Add_new_project;

  const requestDeleteProject = (projectId) => {
    setShowDeleteConfirmation(true);
    setProjectIdToDelete(projectId);
  };

  const cancelDeleteProject = () => {
    setShowDeleteConfirmation(false);
    setProjectIdToDelete(null);
  };
  const handleCardClick = (projectId) => {
    setSelectedProjectId(projectId);
  };
  const confirmDeleteProject = async () => {
    if (projectIdToDelete) {
      setIsDeleting(true);
      try {
        await handleDeleteProject(projectIdToDelete);
      } catch (error) {
        console.error("Error deleting project:", error);
      }
      setIsDeleting(false);
      setShowDeleteConfirmation(false);
      setProjectIdToDelete(null);
    }
  };

  const handleEditClick = (projectId) => {
    setCards(
      cards.map((card) =>
        card.project_id === projectId ? { ...card, editable: true } : card
      )
    );
  };

  const handleNameChange = (projectId, newName) => {
    setCards(
      cards.map((card) =>
        card.project_id === projectId ? { ...card, name: newName } : card
      )
    );
    //  setPin_project(!pin_project)
  };

  const handleUpdateProjectName = async (projectId) => {
    const card = cards.find((c) => c.project_id === projectId);
    if (card) {
      try {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/api/Update-Project/${userId}/${projectId}/`,
          {
            name: card.name,
          }
        );
        setCards(
          cards.map((c) =>
            c.project_id === projectId
              ? { ...c, name: card.name, editable: false }
              : c
          )
        );
        localStorage.setItem("projects", JSON.stringify(cards.map((c) =>
          c.project_id === projectId
            ? { ...c, name: card.name, editable: false }
            : c
        )))

        setPin_project(!pin_project);
      } catch (error) {
        console.error("Failed to update project name:", error);
      }
    }
  };

  const handleDeleteProject = async (projectId) => {
    try {
      await axios
        .delete(
          `${process.env.REACT_APP_API_URL}/api/Delete-Project/${userId}/${projectId}/`
        )
        .then((res) => {
          toast.success("Deleted");
          setCards(cards.filter((card) => card.project_id !== projectId));

          localStorage.setItem("projects", JSON.stringify(cards.filter((card) => card.project_id !== projectId)));

          currentProjects = currentProjects.filter(
            (card) => card.project_id !== projectId
          );
          if (currentProjects.length === 0) {
            setCurrentPage((prev) => prev - 1);
          }
          setPin_project(!pin_project);
        })
        .catch((err) => {
          toast.error(err.data.error);
        });
    } catch (error) {
      console.error("Failed to delete project:", error);
      toast.error("something went wrong, try again");
    }
  };



  const handleOpenProject = (userId, projectId, projectDetails) => {
    setActiveProject(projectId); // Set active project when opened
    console.log("insideProjects", projectId, activeProject);

    navigate(`/projects/${projectId}/`, {
      state: { projectDetails },
    });
  };

  const togglePinProject = async (
    projectName,
    projectId,
    forceUnpin = false,
    pinnedByUser = false
  ) => {
    const pinnedCount = cards.filter((card) => card.isPinned).length;
    const project = cards.find((card) => card.project_id === projectId);

    if (!forceUnpin && pinnedCount >= 3 && !project.isPinned) {
      setProjectToPin(projectId);
      setProjectToPinName(projectName);
      setPinnedProjects(cards.filter((card) => card.isPinned));
      setShowPinModal(true);
      return;
    }
    if (!forceUnpin && pinnedCount === 3 && project.isPinned) {
      return;
    }
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/toggle_project_flag/${userId}/${projectId}/`,
        { pinnedByUser: pinnedByUser },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setPin_project(!pin_project);
      setCurrentPage(1);
      fetchProjects();
    } catch (error) {
      console.error("Error toggling pin:", error);
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    setCsrfToken(token);
  }, []);

  const fetchProjects = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/Displayprojects/${userId}/`
      );

      let fetchedProjects = response.data.projects.map((project) => ({
        ...project,
        isPinned: project.flag,
      }));
      localStorage.setItem("projects", JSON.stringify(fetchedProjects));

      const pinnedProjects = fetchedProjects.filter(
        (project) => project.isPinned
      );
      const unpinnedProjects = fetchedProjects.filter(
        (project) => !project.isPinned
      );

      setCards([...pinnedProjects, ...unpinnedProjects]);

      const processedChartData = {};
      fetchedProjects.forEach((project) => {
        const last5Ranks = project.last_five_ranks.map((rank) =>
          typeof rank === "string" ? JSON.parse(rank.replace(/'/g, '"')) : rank
        );
        const chartData = last5Ranks.map((rank) => ({
          x: new Date(rank.day),
          y: parseFloat(rank.average_rank),
        }));

        processedChartData[project.project_id] = [
          { name: "Average Rank", data: chartData },
        ];
      });

      setChartSeries(processedChartData);

    } catch (error) {
      console.error("Failed to fetch projects:", error);
    } finally {
      setLoading(false);
      getTopThreePinnedProjects()

    }
  };

  // const getTopThreePinnedProjects = async () => {
  //       let projects = [];

  //       const cachedProjects = localStorage.getItem("projects");
  //       if (cachedProjects) {
  //         try {
  //           projects = JSON.parse(cachedProjects);
  //           console.log("Using cached projects:", projects);

  //           const cachedPinnedProjects = projects
  //             .filter((project) => project.flag)
  //             .sort((a, b) => b.rank_count - a.rank_count)
  //             .slice(0, 3); // Take top 3
  //           console.log("Top 3 Projects from Cached Data:", cachedPinnedProjects);

  //           setTopThreeProjects(cachedPinnedProjects);
  //         } catch (error) {
  //           console.error("Error parsing cached projects:", error);
  //           projects = [];
  //         }
  //       } else {
  //         // Step 2: Fetch projects from API if not cached
  //         try {
  //           const response = await axios.get(
  //             `${process.env.REACT_APP_API_URL}/api/Displayprojects/${userId}/`
  //           );
  //           projects = response.data.projects || [];
  //           console.log("Fetched projects from API:", projects);

  //           // Cache the fetched projects
  //           localStorage.setItem("projects", JSON.stringify(projects));

  //           // Ensure projects is an array before proceeding
  //           if (!Array.isArray(projects)) {
  //             console.error("API response projects are not in array format. Resetting to an empty array.");
  //             projects = [];
  //           }

  //           // Log top 3 projects directly from API data
  //           const apiPinnedProjects = projects
  //             .filter((project) => project.flag) // Filter pinned projects
  //             .sort((a, b) => b.rank_count - a.rank_count) // Sort by rank_count
  //             .slice(0, 3); // Take top 3
  //           console.log("Top 3 Projects from API Data:", apiPinnedProjects);

  //           // Set the top three projects
  //           setTopThreeProjects(apiPinnedProjects);
  //         } catch (error) {
  //           console.error("Error fetching projects from API:", error);
  //           return;
  //         }
  //       }
  //     };

  useEffect(() => {
    const cachedProjects = localStorage.getItem("projects");
    if (cachedProjects) {
      console.log(JSON.parse(cachedProjects), 'projectys');
      const pinnedProjects = JSON.parse(cachedProjects).filter(
        (project) => project.isPinned
      );
      const unpinnedProjects = JSON.parse(cachedProjects).filter(
        (project) => !project.isPinned
      );

      setCards([...pinnedProjects, ...unpinnedProjects]);

      const processedChartData = {};
      JSON.parse(cachedProjects).forEach((project) => {
        const last5Ranks = project.last_five_ranks.map((rank) =>
          typeof rank === "string" ? JSON.parse(rank.replace(/'/g, '"')) : rank
        );
        const chartData = last5Ranks.map((rank) => ({
          x: new Date(rank.day),
          y: parseFloat(rank.average_rank),
        }));

        processedChartData[project.project_id] = [
          { name: "Average Rank", data: chartData },
        ];
      });

      setChartSeries(processedChartData);
    } else {
      fetchProjects();
    }
  }, [userId]);
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;

  currentProjects = cards?.slice(indexOfFirstProject, indexOfLastProject);
  const totalPages = Math.ceil(cards?.length / projectsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      {showPinModal && (
        <PinReplaceModal
          loadingPin={loadingPin}
          projectToPinName={projectToPinName}
          pinnedProjects={pinnedProjects}
          onReplace={handleReplacePin}
          setShowPinModal={setShowPinModal}
        />
      )}
      <div className="flex justify-between mt-[20px] pt-[50px] px-[20px] md:px-[50px] mb-[10px]">
        <h1 className="text-greeng  dark:text-gray-300  font-bold mt-3   text-lg 2xl:text-xl xl:text-lg md:text-sm">
          All Projects
        </h1>

        <div className="mt-3 relative td md:mt-0">
          {(notManage || manageRoles) && (
            <button
              className={`flex items-center justify-center bg-mainColor text-white py-2 px-4 md:px-[30px] md:py-[10px] rounded-lg transition duration-150 ease-in-out text-[1rem]`}
              onClick={() => setShowModal(true)}
            >
              <FiPlus size={18} className="mr-2" /> New Project
            </button>
          )}
          {!verfied && (
            <>
              <button
                className={`flex items-center justify-center bg-mainColor text-white py-2 px-4 md:px-[30px] md:py-[10px] rounded-lg transition duration-150 ease-in-out text-[1rem] cursor-not-allowed`}
                onClick={() => setShowModal(true)}
                disabled={true}
              >
                <FiPlus size={18} className="mr-2" /> New Project
              </button>
              <div
                className="tooltipcontainer absolute top-0 hover:-left-60 -left-0 pointer  hover:w-auto transition-all
               duration-1000 ease-in-out"
              >
                <span className="hidden md:block tooltiptext w-max py-3 px-2">
                  Please verify your account through email
                  <span className="underline"> {EmailUser}</span>
                </span>
              </div>
            </>
          )}
        </div>

        <SimpleModal
          setPin_project={setPin_project}
          pin_project={pin_project}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          userId={userId}
          existingProjectNames={cards.map((card) => card.name)}
          onProjectCreated={(newProject) => {
            setCards((prevCards) => [newProject, ...prevCards]);
            fetchProjects()
            setShowModal(false);
          }}
        />
      </div>

      <ProjectCardList
        loading={loading}
        projectsPerPage={projectsPerPage}
        currentProjects={currentProjects}
        chartOptions={chartOptions}
        chartSeries={chartSeries}
        loadingCharts={loadingCharts}
        handleCardClick={handleCardClick}
        selectedProjectId={selectedProjectId}
        handleNameChange={handleNameChange}
        handleUpdateProjectName={handleUpdateProjectName}
        handleEditClick={handleEditClick}
        togglePinProject={togglePinProject}
        handleOpenProject={handleOpenProject}
        requestDeleteProject={requestDeleteProject}
        managed={managed}
        roles={roles}
        showDeleteConfirmation={showDeleteConfirmation}
        cancelDeleteProject={cancelDeleteProject}
        confirmDeleteProject={confirmDeleteProject}
        isDeleting={isDeleting}
        extractDomainName={extractDomainName}
        formatDate={formatDate}
        userId={userId}
      />

      {cards.length > 6 && (
        <PagePaginationProjects
          currentPage={currentPage}
          totalPages={totalPages}
          paginate={paginate}
        />
      )}
    </>
  );
};

export default Projects;
